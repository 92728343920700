<template>
	<div>
		<div class="mg-r-1 mg-l-1 mg-t-4 fix-top-left" v-if="user != null">
			<router-link to="/select-mode" class="color-lighter">
				<i class="el-icon-back"></i> ย้อนกลับ
			</router-link>
		</div>
		<div class="text-right mg-r-1 mg-t-4 fix-top-right" v-if="user == null">
			<router-link to="/sign-in">
				<el-button  class="mg-y-5 " type="primary" :loading="loadingNext" plain round>Sign in</el-button>
			</router-link>
		</div>
		<DefaultTemplate>
			<el-alert
	            v-if="magErrorValid !== ''"
	            title="แจ้งเตือน"
	            type="error"
	            :closable="false"
	            :class="{'shake animated': animated}"
	            :description="magErrorValid"
	            class="mg-y-4 capital"
	            show-icon>
	        </el-alert>
			<el-form @submit.native.prevent="submit()" label-position="top" :model="form" :rules="rules" ref="ruleForm" label-width="120px" >
				<el-form-item label="Game PIN" prop="number">
					<el-input class="rounded" v-model="form.number"></el-input>
				</el-form-item>
			</el-form>
			<el-button class="mg-y-5 w-100" type="primary" @click="submit()" :loading="loadingNext" round>Join game</el-button>
			<!-- <button class="mg-y-5 w-100 btn-primary" @click="submit()" type="submit">Join game</button> -->
		</DefaultTemplate>	
	</div>
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
import DefaultTemplate from '@/template/DefaultTemplate'
export default {
	computed:{
		user(){
			return this.$store.state.user
		}
	},
	components:{
		DefaultTemplate
	},
	data(){
		return{
			loadingNext:false,
			animated:true,
			form:{
				number:'',
			},
			magErrorValid:'',
			rules: {
				number: [
					{ required: true, message: 'Please input Game PIN', trigger: 'blur' },
				],  
        	}
		}
	},
	mounted(){
		this.$store.commit('SET_FINAN_PRODUCTS',null)
	},
	methods:{
		submit(){
			this.loadingNext = true
			this.animated = false
			this.$refs['ruleForm'].validate((valid) => {
          		if (valid) {
          			HTTP.post('/search/room',this.form).then(res=>{
          				if(res.data.success){
          					this.$store.commit('SET_ROOM',res.data.obj)
							this.$router.push('/start').catch(err => {})
						}else{
							this.animated = true
							this.magErrorValid = res.data.data
						}
					}).finally(()=>{
						this.loadingNext = false
					}).catch(e=>{
          				console.log('/login error',e)
          				this.alertFailError()
          			})
          		}else{
          			this.loadingNext = false
          		}
			})
		}
	}
}
</script>